* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.projects-content{
    margin-bottom: 60px;
}
.projects-content h1 {
    color: #960c0c;
    font-size: 32px;
    border-bottom: 1px solid #960c0c;
    padding-top: 15px;
    padding-bottom: 20px;
}
.projects-content p{
    text-align: justify;
    font-size: 14px;
    line-height: 1.42857143;
    color: black;
}
.a-tag{
    color: #337ab7;
    text-decoration-line: none;
}
.a-tag:hover{
    text-decoration-line: underline;
}