* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.distributor-content h1 {
    color: #960c0c;
    font-size: 32px;
    border-bottom: 1px solid #960c0c;
    padding-top: 15px;
    padding-bottom: 20px;
}
.distributor-content p{
    text-align: justify;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    padding: 2px 0;
    max-width: 100%;
}
.distributor-content h4{
    font-size: 18px;
    color: black;
    margin-top: 50px;
    margin-bottom: 10px;
}
.distributor-content{
    margin-bottom: 50px;
}
.form-btn{
    width: 100%;
    font-family: inherit;
    color: inherit;
    border: none;
    border-radius: 5px;
    padding: 5px;
}
.error-message{
    color: red;
}