* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.faq-content h1 {
    color: #960c0c;
    font-size: 32px;
    border-bottom: 1px solid #960c0c;
    padding-top: 15px;
    padding-bottom: 20px;
}
.faq-content p{
    text-align: justify;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    padding: 2px 0;
    max-width: 100%;
}
.faq-content h3{
    font-size: 18px;
    color: black;
}
.faq-content{
    margin-bottom: 40px;
}
.borders-bottom{
    margin: 20px 0px 15px 0px;
    height: 20px;
    background-image: url("https://www.starhomecinema.com/images/border-bottom.png");
}
.a-tag{
    color: #337ab7;
    text-decoration-line: none;
}
.a-tag:hover{
    text-decoration-line: underline;
}




@media screen and (max-width:600px) {
    .faq-content{
        padding: 0px 20px;
    }
}