* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
#company .container{
    width: 80%;
}
.company-content{
    margin-bottom: 70px;
}
.company-content h1 {
    color: #960c0c;
    font-size: 32px;
    border-bottom: 1px solid #960c0c;
    padding-top: 15px;
    padding-bottom: 15px;
}
.content img{
    margin-right: 15px;
    max-width: 100%;
    margin-top: 25px;
}
.content p{
    text-align: justify;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    padding: 2px 0;
}















@media screen and (max-width:1024px) {
    .content img{
        width: 100%;
        margin-bottom: 10px;
    }
    #company .container{
        width: 90%;
    }
}