*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
#expertise .container{
    width: 79%;
}
.expertise-content{
    margin-bottom: 50px;
}
.expertise-content h1 {
    color: #960c0c;
    font-size: 32px;
    border-bottom: 1px solid #960c0c;
    padding-top: 15px;
    padding-bottom: 15px;
}
.expertise-content h2 {
    color: #960c0c;
    font-size: 24px;
}
.expertise-content p {
    text-align: justify;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    padding: 2px 0;
    max-width: 100%;
}
.borders-bottom{
    margin: 25px 0;
    height: 20px;
    background-image: url("https://www.starhomecinema.com/images/border-bottom.png");
}
.a-tag{
    color: #337ab7;
    text-decoration-line: none;
}
.a-tag:hover{
    text-decoration-line: underline;
}
.head-tag{
    color: #960c0c;
    text-decoration-line: none;
}
.head-tag:hover{
    text-decoration-line: underline;
}














@media screen and (max-width:500px) {
    #expertise .container{
        width: 90%;
    }
}