* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.row {
    margin: 0px;
}


/* Nav background style */
.header {
    width: 100%;
    height: auto;
    background-color: black;
    padding: 0px;
}

.phn-logo {
    margin-top: 30px;
}

.phn-logo p {
    color: white;
    font-size: 20px;
}

.phn-logo div {
    display: flex;
    justify-content: center;
}

.phn-logo .col img {
    width: 22px;
    height: 15px;
}

.social-icons {
    padding-left: 180px;
    display: flex;
    justify-content: center;
}

.flag {
    padding-left: 200px;
}

.social-icons a {
    padding-right: 10px;
}

.nav-list {
    background-color: #960c0c;
    height: auto;
}

.nav-ul {
    display: flex;
    padding-top: 10px;
    justify-content: space-around;
    margin: 0;
    padding-inline-start: 0;
}

.nav-ul li {
    list-style-type: none;
    color: white;
    padding: 5px;
}

.nav-ul li:hover {
    color: black;
}

.nav-ul a {
    text-decoration-line: none;
}

.navbar-toggler {
    display: none;
    padding: 0px;
}

.navbar-toggler-icon {
    border: 1px solid #fff;
    border-radius: 2px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}

.navbar-toggler.active .nav-ul {
    display: block;
    padding: 0px;
    background-color: #960c0c;
    padding: 25px;
    text-align: start;
}


/* Carousel style */
.carousel-image {
    position: relative;
}

.carousel-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}

.carousel-text h2 {
    font-size: 50px;
    margin-bottom: 10px;
    font-weight: bold;
}

.carousel-text p {
    font-size: 25px;
}

#carouselExampleIndicators .carousel-indicators {
    bottom: 0;
    margin-bottom: 20px;
}

#carouselExampleIndicators .carousel-indicators button {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #fff;
    background-color: #869791;
    opacity: 0.5;
    margin-right: 5px;
}

#carouselExampleIndicators .carousel-indicators button.active {
    background-color: gainsboro;
    opacity: 1;
}

/* Home Style */
.home-container{
    margin-bottom: 70px;
}
.home-content h2 {
    color: #960c0c;
    font-size: 24px;
    margin-top: 20px;
}

.home-content p {
    text-align: justify;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    padding: 2px 0px;
}

.home-content span {
    color: #ad8c5f;
}

.home-content img {
    width: 80%;
    height: 250px;
}

/* .fixed-image {
    position: fixed;
    top: 40;
    right: 0;
    z-index: 9999; 
    width: 100px;
    height: auto;
} */
.borders-bottom {
    margin: 25px 0;
    height: 20px;
    background-image: url("https://www.starhomecinema.com/images/border-bottom.png");
}
.a-tag{
    color: #337ab7;
    text-decoration-line: none;
}
.a-tag:hover{
    text-decoration-line: underline;
}
.brand{
    margin-top: 40px;
}

/* Footer style */
.footer {
    width: 100%;
    height: auto !important;
    background-image: url("https://www.starhomecinema.com/images/footer-link-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px 0;
}

.footer-para {
    background: rgba(0, 0, 0, .6);
    margin-left: 20px;
}

.footer-links ul {
    list-style-type: none;
    color: white;
    background: rgba(0, 0, 0, .6);
    padding: 20px 50px;
    height: auto;
}

.footer-links ul a {
    text-decoration: none;
    color: white;
}

.footer-links ul a:hover {
    text-decoration: underline;
}

.footer-para p {
    text-align: end;
    list-style-type: none;
    color: white;
    height: auto;
    margin: 0px;
    word-wrap: break-word;
}

.copyrights {
    width: 100%;
    height: 65px;
    background-color: #1e1b17;
}

.copyrights p {
    font-size: 14px;
    font-weight: bold;
    color: white;
    justify-content: center;
    padding-top: 20px;
    display: flex;
}






@media screen and (max-width:1024px) {
    .nav-smg {
        display: none !important;
    }

    .nav-list {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .navbar-toggler {
        display: inline;
    }

    .nav-list {
        display: none;
    }

    .carousel-text h2 {
        font-size: 12px;
    }

    .carousel-text p {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .carousel-inner .carousel-item .carousel-text p {
        display: none;
    }
}

@media (max-width: 768px) {
    .brand-img img {
        margin-top: 10px;
    }
}

@media (max-width: 767px) {
    .footer-para p {
        text-align: center !important;
        margin-bottom: 2px;
    }

    .footer-para {
        margin-top: 10px;
    }
}

@media (max-width: 992px) {
    .footer-para {
        margin-left: 0px;
    }
}
@media (max-width: 576px) {
    .footer-links {
        padding: 0;
    }
}

@media (max-width: 576px) {
    .footer-links {
        text-align: center;
    }
}

@media (max-width: 1025px) {
    .flag {
        padding-left: 0;
    }

    .social-icons {
        padding-left: 0;
    }
}