* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.howework-content h1 {
    color: #960c0c;
    font-size: 32px;
    border-bottom: 1px solid #960c0c;
    padding-top: 15px;
    padding-bottom: 20px;
}

.howework-content h2 {
    color: #960c0c;
    font-size: 24px;
}

.howework-content p {
    text-align: justify;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    padding: 2px 0;
    max-width: 100%;
}

.howework-content .row {
    margin-bottom: 40px;
}

.borders-bottom {
    margin: 20px 0px 20px 0px;
    height: 20px;
    background-image: url("https://www.starhomecinema.com/images/border-bottom.png");
}

.a-tag{
    color: #337ab7;
    text-decoration-line: none;
}
.a-tag:hover{
    text-decoration-line: underline;
}




@media only screen and (max-width: 768px) {
    .howework-content h1 {
        font-size: 28px;
    }

    .howework-content h2 {
        font-size: 22px;
    }

    .howework-content p {
        font-size: 14px;
        line-height: 22px;
    }

    .howework-content img {
        max-width: 100%;
        height: auto;
    }
}