* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.associates-content h1 {
    color: #960c0c;
    font-size: 32px;
    border-bottom: 1px solid #960c0c;
    padding-top: 15px;
    padding-bottom: 20px;
}
.associates-content h3{
    color: #960c0c;
    font-size: 20px;
}
.associates-content h4{
    color: #9D9D9D;
    font-size: 15px;
}
.associates-content p{
    text-align: justify;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    padding: 2px 0;
}
.btn{
    background-color: #960c0c;
    color: white;
}
.brand-image{
    padding: 0 15px 0 0;
}




@media screen and (max-width:1025px) {
    .associates1{
        padding-left: 0px;
    }
}