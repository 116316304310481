* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.contactus-content h1 {
    color: #960c0c;
    font-size: 32px;
    border-bottom: 1px solid #960c0c;
    padding-top: 15px;
    padding-bottom: 15px;
}
.contactus-content strong{
    font-size: 18px;
    text-decoration: underline;
    color: black;
}
.contactus-content p{
    margin: 0;
    font-size: 14px;
}
.contactus-content{
    margin-bottom: 50px;
}